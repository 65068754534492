<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <ventaspormes />
      </v-col>
      <v-col cols="6">
        <saldos />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ventaspormes from '@/views/crmpro/estadisticas/ventaspormes.vue'
import saldos from '@/views/crmpro/estadisticas/saldos.vue'
export default {
  components: {
    ventaspormes,
    saldos
  },
}
</script>

<style>

</style>